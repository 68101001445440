<template>
  <v-container grid-list-xl>
    <app-card heading="Bill customers">
      <v-layout
        row
        wrap>
        <v-flex
          xs6
          sm3>
          <v-select
            v-if="brandList.length"
            :items="brandList"
            v-model="filters.brand"
            item-text="name"
            item-value="id"
            label="Brand"
            hide-details
            required
          />
        </v-flex>
        <v-flex
          xs6
          sm3>
          <passengers-input
            :disabled="loading"
            v-model="filters.passenger"
            clearable
            hide-details
          />
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-menu
            ref="dateFrom"
            v-model="dateFromFilterMenu"
            :close-on-content-click="false"
            :return-value.sync="filters.dateFrom"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters.dateFrom"
                v-bind="attrs"
                label="Date from"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="filters.dateFrom"
              :first-day-of-week="1"
              no-title
              scrollable
              range
              @change="
                () => {
                  $refs.dateFrom.save(filters.dateFrom)
                  dateFromFilterMenu = false
                }
              "
            />
          </v-menu>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-menu
            ref="dateTo"
            v-model="dateToFilterMenu"
            :close-on-content-click="false"
            :return-value.sync="filters.dateTo"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters.dateTo"
                v-bind="attrs"
                label="Date to"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="filters.dateTo"
              :first-day-of-week="1"
              no-title
              scrollable
              range
              @change="
                () => {
                  $refs.dateTo.save(filters.dateTo)
                  dateToFilterMenu = false
                }
              "
            />
          </v-menu>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-select
            :items="rideStatusList"
            v-model="filters.rideStatus"
            label="Statuses"
            item-value="value"
            item-text="text"
            clearable
            multiple
            hide-details
          >
            <template slot="item" slot-scope="data">
              <v-list-tile
                :class="data.item.default === true ? `default-option-status` : ''"
                @click="toggleItem(data.item)">
                <v-list-tile-action>
                  <v-checkbox :input-value="isChecked(data.item)" primary />
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <strong v-if="isChecked(data.item)">{{ data.item.text }}</strong>
                    <span v-else>
                      <span>{{ data.item.text }}</span>
                    </span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-select>
        </v-flex>
        <!-- <v-flex
          xs6
          sm4
          md3>
          <group-switch
            :value="filters.zzp"
            :option-values="zzpOptionValues"
            label="ZZP"
            @onChange="onZzpChange" />
        </v-flex>
        <v-flex
          xs6
          sm4
          md3>
          <group-switch
            :value="filters.kor"
            :option-values="korOptionValues"
            label="KOR"
            @onChange="onKorChange" />
        </v-flex> -->
      </v-layout>
      <v-layout
        row
        wrap>
        <!-- <v-flex
          xs6
          sm3>
          <v-btn
            dark
            color="secondary"
            @click="openModalExportCsv">
            Export CSV
          </v-btn>
        </v-flex> -->
        <v-flex
          xs6
          sm3>
          <v-btn
            :disabled="!selectedPassengers || !selectedPassengers.length"
            dark
            color="secondary"
            @click="generateCombinedInvoicesOpenDialog">
            Generate invoices
          </v-btn>
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="headers"
        :items="billPassengers"
        :loading="loading"
        class="elevation-1 bill-passengers-table"
        hide-actions
      >
        <template v-slot:headers="props">
          <tr>
            <th
              v-for="(header, key) in props.headers"
              :key="key"
              :class="[
                'column',
                header.customSortable && 'sortable',
                header.customSortable && (filters.sortDir === 'desc' ? 'desc' : 'asc'),
                header.value === filters.sort ? 'active' : '',
              ]"
              @click="changeTableSort(header)"
            >
              <v-icon
                v-if="header.customSortable"
                small
                color="white">arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr class="passenger-row">
            <td @click="() => { onClickPassengerRow(props.item) }">
              {{ props.item.passenger.id }}
            </td>
            <td @click="() => { onClickPassengerRow(props.item) }">{{ props.item.passenger.name }}</td>
            <td @click="() => { onClickPassengerRow(props.item) }">{{ props.item.duration && props.item.duration.time }}</td>
            <td @click="() => { onClickPassengerRow(props.item) }">{{ props.item.expenses.passenger }}</td>
            <td @click="() => { onClickPassengerRow(props.item) }">
              {{ momentDateTime(props.item.invoicedAt) }}
              <v-dialog
                v-model="props.item.invoiceEditDialog"
                width="500"
              >
                <!-- <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    color="primary"
                    dark
                    small
                    v-on="on"
                  >
                    <v-icon>
                      edit
                    </v-icon>
                  </v-btn>
                </template> -->

                <v-card>
                  <v-card-title class="text-h5 lighten-2">
                    Invoiced At edit
                  </v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="props.item.invoicedAt"
                      label="Invoiced at"
                      type="datetime-local"
                    />
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                      color="primary"
                      text
                      @click="invoicedAtEdit(props.item)"
                    >
                      save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </td>
            <td @click="() => { onClickPassengerRow(props.item) }"/>
            <td @click="() => { onClickPassengerRow(props.item) }">{{ filters.brand === 2 ? props.item.price.driver.total : props.item.price.driver.vatExcl }}</td>
            <td @click="() => { onClickPassengerRow(props.item) }">{{ filters.brand === 2 ? props.item.price.passenger.total : props.item.price.passenger.vatExcl }}</td>
            <td @click="() => { onClickPassengerRow(props.item) }"/>
            <td>
              <v-checkbox
                v-model="props.item.isSelected"
                hide-details
                class="justify-center"
              />
            </td>
          </tr>
          <tr
            v-for="ride of props.item.rides"
            :key="ride.id"
            class="ride-row">
            <td
              v-if="props.item.isShowRides"
              :colspan="headers.length">
              <div :class="`d-flex align-center ride-row-container ${ride.status.alias === 'declaration_approved' && 'ride-row-container-invoiced'}`">
                <div class="spacer">
                  <router-link
                    :to="`/dashboard/ride-overview?rideId=${ride.id}`"
                    class="fs-15"
                    target="_blank"
                  >
                    {{ ride.id }}
                  </router-link>
                </div>
                <div
                  v-if="ride.driver.name"
                  class="spacer">
                  {{ ride.driver.name }}
                </div>
                <div
                  v-if="ride.companyName"
                  class="spacer">
                  {{ ride.companyName }}
                </div>
                <div
                  v-if="ride.startAt && ride.endAt"
                  class="spacer">
                  {{ momentDate(ride.startAt) }}
                </div>
                <div
                  v-if="ride.startAt && ride.endAt"
                  class="spacer">
                  {{ momentTime(ride.startAt) }} - {{ momentTime(ride.endAt) }}
                </div>
                <div
                  v-if="ride.stops && ride.stops.length"
                  class="spacer">
                  {{ ride.stops[0].address }} -
                  {{ ride.stops[ride.stops.length - 1].address }}
                </div>
                <div
                  v-if="ride.map.distance.full"
                  class="spacer">
                  {{ parseFloat((ride.map.distance.full / 1000).toFixed(1)) }} km
                </div>
                <div class="spacer">
                  {{ ride.stops && ride.stops.length }} stops
                </div>
                <div
                  v-if="ride.extraWaitingTime"
                  class="spacer">
                  {{ ride.extraWaitingTime.minutes }} minutes
                </div>
                <div
                  v-if="ride.expenses"
                  class="spacer">
                  {{ ride.expenses.passenger }} expenses
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </app-card>
    <v-dialog
      v-model="exportCsvDialog.display"
      max-width="500">
      <v-card>
        <v-container>
          <h2
            class="mb-3"
            align="center">Export csv</h2>
          <v-layout
            row
            wrap>
            <v-flex
              xs12
              sm6>
              <v-menu
                ref="month"
                v-model="monthFilterMenu"
                :close-on-content-click="false"
                :return-value.sync="exportCsvDialog.month"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="exportCsvDialog.month"
                    v-bind="attrs"
                    label="Month"
                    prepend-icon="mdi-calendar"
                    class="mr-2"
                    readonly
                    clearable
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="exportCsvDialog.month"
                  no-title
                  scrollable
                  range
                  type="month"
                  @change="
                    () => {
                      $refs.month.save(exportCsvDialog.month)
                      monthFilterMenu = false
                    }
                  "
                />
              </v-menu>
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                v-if="brandList.length"
                :items="brandList.filter(item => item.id !== 2)"
                v-model="exportCsvDialog.brand"
                item-text="name"
                item-value="id"
                label="Brand"
                class="ml-2"
                hide-details
                required
              />
            </v-flex>
          </v-layout>
          <div class="d-flex justify-center">
            <v-btn
              color="primary"
              @click="exportCsv">Export</v-btn>
            <v-btn
              color="error"
              @click="exportCsvDialog.display = false">Cancel</v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="generateCombinedInvoicesDialog.display"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          Invoiced At
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="generateCombinedInvoicesDialog.data.invoicedAt"
            label="Invoiced at"
            type="date"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="generateCombinedInvoices"
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import PassengersInput from '../../../modules/components/PassengersInput.vue'
import moment from 'moment-timezone'
import csvExport from '../../../helpers/csvExport'
import GroupSwitch from 'Components/GroupSwitch'

export default {
  components: { PassengersInput, GroupSwitch },
  mixins: [Request, Brands],
  data () {
    return {
      moment,
      brandList: [],
      generateCombinedInvoicesDialog: {
        display: false,
        data: {
          invoicedAt: moment().format('YYYY-MM-DD')
        }
      },
      headers: [
        {
          text: 'Customer ID',
          align: 'center',
          sortable: false,
          customSortable: true,
          value: 'passengerId'
        },
        { text: 'Customer name', align: 'center', sortable: false },
        { text: 'Duration', align: 'center', sortable: false },
        { text: 'Expenses', align: 'center', sortable: false },
        { text: 'Invoiced at', sortable: false },
        { text: '', sortable: false },
        { text: 'Total out', sortable: false },
        { text: 'Total in', sortable: false },
        { text: '', sortable: false },
        { text: 'Select', sortable: false }
      ],
      billPassengers: [],
      loading: false,
      sorting: [
        {
          title: 'Customer Id',
          name: 'passengerId',
          dir: 'asc'
        }
      ],
      filters: {
        brand: null,
        zzp: null,
        kor: null,
        dateFrom: null,
        dateTo: null,
        passenger: null,
        rideStatus: ['declaration_approved', 'driver_invoiced']
      },
      dateFromFilterMenu: false,
      dateToFilterMenu: false,
      monthFilterMenu: false,
      rideStatusList: [
        { text: 'Declaration approved', value: 'declaration_approved', default: true },
        { text: 'Invoiced', value: 'invoiced' },
        { text: 'Paid', value: 'paid' },
        { text: 'Driver invoiced', value: 'driver_invoiced', default: true },
        { text: 'Passenger invoiced', value: 'passenger_invoiced' },
        { text: 'Paid out', value: 'paid_out' }
      ],
      csvData: [],
      exportCsvDialog: {
        display: false,
        month: null,
        brand: null
      }
      // zzpOptionValues: {
      //   on: { text: 'ON', value: true },
      //   middle: { text: '-', value: 'none' },
      //   off: { text: 'OFF', value: false }
      // },
      // korOptionValues: {
      //   on: { text: 'ON', value: true },
      //   middle: { text: '-', value: 'none' },
      //   off: { text: 'OFF', value: false }
      // }
    }
  },
  computed: {
    selectedPassengers () {
      return this.billPassengers.filter(item => item.isSelected)
    }
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.updateParams()
      }
    },
    'filters.brand': {
      deep: true,
      handler () {
        this.exportCsvDialog.brand = this.filters.brand
      }
    }
  },
  async beforeMount () {
    try {
      const [brandList] = await Promise.all([this.fetchBrands()])

      this.brandList = brandList

      const updatedProps = {}
      const _filters = { ...this.filters }

      if (this.brandList && this.brandList.length) {
        _filters.brand = this.brandList[2].id
      }

      if (this.$route.query.sort) {
        _filters.sort = this.$route.query.sort
        updatedProps.sort = this.$route.query.sort
      } else {
        _filters.sort = this.sorting[0].name
        updatedProps.sort = this.sorting[0].name
      }

      if (this.$route.query.sortDir) {
        _filters.sortDir = this.$route.query.sortDir
        updatedProps.sortDir = this.$route.query.sortDir
      } else {
        _filters.sortDir = this.sorting[0].dir
        updatedProps.sortDir = this.sorting[0].dir
      }

      if (this.$route.query.passenger) {
        _filters.passenger = this.$route.query.passenger
        updatedProps.passenger = this.$route.query.passenger
      }

      if (this.$route.query.brand) {
        _filters.brand = parseInt(this.$route.query.brand)
        updatedProps.brand = parseInt(this.$route.query.brand)
      }

      if (this.$route.query.rideStatus) {
        _filters.rideStatus = this.$route.query.rideStatus.split(',')
        updatedProps.rideStatus = this.$route.query.rideStatus
      }

      // if (this.$route.query.zzp !== 'none' && this.$route.query.zzp !== undefined) {
      //   _filters.zzp = JSON.parse(this.$route.query.zzp)
      //   updatedProps.zzp = JSON.parse(this.$route.query.zzp)
      // } else {
      //   _filters.zzp = 'none'
      // }

      // if (this.$route.query.kor !== 'none' && this.$route.query.kor !== undefined) {
      //   _filters.kor = JSON.parse(this.$route.query.kor)
      //   updatedProps.kor = JSON.parse(this.$route.query.kor)
      // } else {
      //   _filters.kor = 'none'
      // }

      if (this.$route.query.dateFrom) {
        _filters.dateFrom = this.$route.query.dateFrom
        updatedProps.dateFrom = this.timeStamp(this.$route.query.dateFrom)
      }

      if (this.$route.query.dateTo) {
        _filters.dateTo = this.$route.query.dateTo
        updatedProps.dateTo = this.timeStamp(this.$route.query.dateTo)
      }

      this.filters = _filters

      this.getInvoicesPreview(updatedProps)
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    // invoicedAtEdit (invoice) {
    //   const body = { data: { invoicedAt: invoice.invoicedAt, rides: invoice.rides.map(item => item.id) } }
    //   this.request('PATCH', `/combined-invoices/${invoice.id}`, body, ({ data }) => {
    //     invoice.invoiceEditDialog = false

    //     this.$notify({
    //       group: 'topRight',
    //       type: 'success',
    //       text: 'invoicedAt has been updated'
    //     })
    //   })
    // },
    isChecked (item) {
      return this.filters.rideStatus.includes(item.value);
    },
    toggleItem (item) {
      const index = this.filters.rideStatus.indexOf(item.value)
      if (index >= 0) {
        this.filters.rideStatus.splice(index, 1)
      } else {
        this.filters.rideStatus.push(item.value)
      }
    },
    onClickPassengerRow (invoicePreview) {
      this.billPassengers = this.billPassengers.map(item => {
        if (item.passenger.id === invoicePreview.passenger.id) {
          item.isShowRides = !item.isShowRides
        }
        return item
      })
    },
    // onKorChange (value) {
    //   this.filters.kor = value
    // },
    // onZzpChange (value) {
    //   this.filters.zzp = value
    // },
    openModalExportCsv () {
      this.exportCsvDialog.display = true
    },
    generateCombinedInvoicesOpenDialog () {
      this.generateCombinedInvoicesDialog.display = true
    },
    async generateCombinedInvoices () {
      this.loading = true
      const bodyData = this.selectedPassengers.filter(item => item.passenger.id && item.rides && item.rides.length).map((item, index) => ({
        key: index + 1,
        brand: this.filters.brand,
        passenger: item.passenger.id,
        rides: item.rides.map(item => item.id),
        invoicedAt: moment(this.generateCombinedInvoicesDialog.data.invoicedAt).set('hours', 0).format('YYYY-MM-DDTHH:mm')
      }))

      await this.request('POST', `/combined-invoices/bulk`, { data: bodyData })

      this.$notify({
        group: 'topRight',
        type: 'success',
        text: 'invoices successfully created'
      })

      this.generateCombinedInvoicesDialog = {
        display: false,
        data: {
          invoicedAt: moment().format('YYYY-MM-DD')
        }
      }

      this.updateParams()

      this.loading = false
    },
    async initCsvData () {
      const body = {
        params: {
          month: this.exportCsvDialog.month,
          brand: this.exportCsvDialog.brand
        }
      }
      await this.request('GET', `/export/driver/finance`, body, ({ data }) => {
        this.loading = false
        this.csvData = data.filter(item => !item.driver.zzp).map(item => {
          return {
            'Debiteurnr': 300963,
            'Bedrijnr': 1,
            'Persnr': item.driver.personnelNumber || '-',
            'Naam': item.driver.fullName || '-',
            'Periode': this.exportCsvDialog.month,
            'Dagen': item.rides.length || '-',
            'DagenVoorLC': '',
            '': '',
            'U2100': item.duration,
            'L5100': '',
            'L5130': item.expenses && item.expenses.driver || '-',
            'L5210': item.expenses && item.expenses.passenger || '-',
            'L5219': ''
          }
        })
      })
    },
    async exportCsv () {
      await this.initCsvData()
      if (this.csvData && this.csvData.length) {
        csvExport('bill-passengers', this.csvData)
      } else {
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Export data is empty'
        })
      }
    },
    async getInvoicesPreview (params = {}) {
      this.loading = true
      let body = { params: { ...params, pageSize: 200, groupBy: 'passenger' } }

      await this.request('GET', `/invoices-preview/passenger`, body, ({ data }) => {
        this.loading = false
        for (let item of data.data) {
          const sortByStatusRides = item.rides.sort((a, b) => {
            return a.status.alias === 'declaration_approved' && b.status.alias !== 'declaration_approved' ? -1 : 1
          })

          const sortByDateRides = sortByStatusRides.sort((a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime())

          item = { ...item, rides: sortByDateRides }
        }
        this.billPassengers = data.data.map(item => ({ ...item, invoiceEditDialog: false }))
      })
    },
    onChangeBrands (brandsId) {
      this.filters.brand = brandsId
    },
    updateParams () {
      let params = {}

      let updatedRouteQuery = { ...this.$route.query }

      if (this.filters.sort) {
        params.sort = this.filters.sort
        updatedRouteQuery = {
          ...updatedRouteQuery,
          sort: this.filters.sort
        }
      }

      if (this.filters.sortDir) {
        params.sortDir = this.filters.sortDir
        updatedRouteQuery = {
          ...updatedRouteQuery,
          sortDir: this.filters.sortDir
        }
      }

      if (this.filters.brand) {
        updatedRouteQuery = { ...updatedRouteQuery, brand: this.filters.brand }
        params.brand = this.filters.brand
      } else {
        delete updatedRouteQuery.brand
        delete params.brand
      }

      // if (this.filters.zzp !== 'none' && this.filters.zzp !== undefined) {
      //   updatedRouteQuery = {
      //     ...updatedRouteQuery,
      //     zzp: this.filters.zzp
      //   }
      //   params.zzp = this.filters.zzp
      // } else {
      //   delete updatedRouteQuery.zzp
      //   delete params.zzp
      // }

      // if (this.filters.kor !== 'none' && this.filters.kor !== undefined) {
      //   updatedRouteQuery = {
      //     ...updatedRouteQuery,
      //     kor: this.filters.kor
      //   }
      //   params.kor = this.filters.kor
      // } else {
      //   delete updatedRouteQuery.kor
      //   delete params.kor
      // }

      if (this.filters.rideStatus && this.filters.rideStatus.length) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          rideStatus: this.filters.rideStatus.join(',')
        }
        params.rideStatus = this.filters.rideStatus.join(',')
      } else {
        delete updatedRouteQuery.rideStatus
        delete params.rideStatus
      }

      if (this.filters.dateFrom) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          dateFrom: this.filters.dateFrom
        }
        params.dateFrom = this.timeStamp(this.filters.dateFrom)
      } else {
        delete updatedRouteQuery.dateFrom
        delete params.dateFrom
      }

      if (this.filters.dateTo) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          dateTo: this.filters.dateTo
        }
        params.dateTo = this.timeStamp(
          this.moment(this.filters.dateTo).set('hours', 24)
        )
      } else {
        delete updatedRouteQuery.dateTo
        delete params.dateTo
      }

      if (this.filters.passenger) {
        const passengerId = this.filters.passenger.id || this.filters.passenger
        params.passenger = passengerId
        updatedRouteQuery = { ...updatedRouteQuery, passenger: passengerId }
      } else {
        delete updatedRouteQuery.passenger
        delete params.passenger
      }

      this.$router.replace({ query: updatedRouteQuery }).catch((err) => err)

      this.getInvoicesPreview(params)
    },
    changeTableSort (headerColumn) {
      if (!headerColumn.customSortable) {
        return null
      }

      this.filters = {
        ...this.filters,
        sort: headerColumn.value,
        sortDir: this.filters.sortDir === 'desc' ? 'asc' : 'desc'
      }
    }
  }
}
</script>

<style lang="scss">
.bill-passengers-table {
  table {
    min-width: 100% !important;
    width: max-content !important;
    max-width: initial !important;

    th {
      text-align: center;
      border-right: 2px solid #353535;
      background-color: #424242 !important;
      color: white !important;

      &:first-child {
        border-left: 2px solid #353535;
      }
    }
  }
}

.passenger-row, .driver-row {
  td {
    text-align: center;
    border-right: 2px solid lightgray;
    cursor: pointer;

    &:first-child {
      border-left: 2px solid lightgray;
    }
  }
}
.ride-row {
  td {
    padding: 0 !important;
  }

  .spacer {
    font-weight: 500;
    font-size: 13px;
    flex: 0 0 auto !important;
    display: flex;
    align-items: center;

    &:not(:last-child)::after {
      content: '';
      display: flex;
      border-radius: 50%;
      background-color: gray;
      width: 6px;
      height: 6px;
      margin: 0 20px;
    }
  }

  .ride-row-container {
    padding: 0 24px;
    height: 100%;
    background-color: lightgrey !important;
    border-bottom: 2px solid gray;
  }

  .ride-row-container-invoiced {
    background-color: #00425366 !important
  }
}

.default-option-status {
  background: rgba(0, 0, 0, 0.1) !important;
}
</style>
